.segment-wrapper {
  @apply inline-flex flex-row;
}

.segment {
  @apply bg-white p-1.5 px-3 border-t border-b hover:cursor-pointer transition-all font-medium text-slate-800 text-sm border-slate-300 select-none flex-shrink-0;

  &:first-child {
    @apply rounded-l-md border;
  }
  &:last-child {
    @apply rounded-r-md border;
  }

  &.active {
    @apply shadow-inner border-slate-900 bg-slate-900 text-white;
    &:hover {
      @apply bg-slate-800 text-white;
    }
  }

  &:hover {
    @apply bg-slate-100 text-slate-900 shadow-inner;
  }
}

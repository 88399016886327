table.e-table {
  thead {
    tr {
      @apply border-b border-slate-500;
      th {
        @apply  font-medium ;
      }
    }
  }
  tbody {
    tr {
      td {
        // @apply text-base;
        @apply  text-slate-900;
      }

      &:hover {
        @apply bg-slate-100;
      }
    }
  }
  .link {
    @apply text-blue-800 font-medium;
    &:hover {
      @apply cursor-pointer underline;
    }
  }
  &.end-btns {
    tbody {
      tr {
        td:last-of-type {
          @apply flex justify-end gap-2;
        }
      }
    }
  }
}

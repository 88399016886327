.navbar {
  background: url("../../assets/images/bg-login.webp") no-repeat top right;
  background-size: cover;
  display: flex;
  flex-direction: column;
  gap: 1.5rem; /* gap-6 */
  background-color: #3b82f6; /* bg-blue-600 */
}

.navbar.collapsed .navbar--top {
  align-items: center; /* items-center */
  justify-content: center; /* justify-center */
  padding: 0.75rem; /* px-3 */
}

.navbar--top {
  display: flex;
  flex-direction: row; /* flex-row */
  align-items: center; /* items-center */
  gap: 0.75rem; /* gap-3 */
  margin-bottom: 2.5rem; /* mb-10 */
  padding: 1.5rem; /* p-6 */
}

.navbar--body {
  display: flex;
  flex: 1;
  flex-direction: column; /* flex-col */
  width: 100%; /* w-full */
  padding: .75rem; /* px-3 */
}

.navbar--footer {
  padding: 0.75rem; /* p-3 */
}

.nav-item {
  cursor: pointer;
  padding: 0.75rem; /* p-3 */
  height: 3rem; /* h-12 */
  width: 100%; /* w-full */
  border-radius: 0.375rem; /* rounded */
  color: #ffffff; /* text-white */
  font-weight: 600; /* font-semibold */
  margin-bottom: 0.25rem; /* mb-1 */
  display: flex;
  flex-direction: row; /* flex-row */
  align-items: center; /* items-center */
  gap: 0.75rem; /* gap-3 */
  transition: all 0.3s; /* transition-all */



  &.active {
    background-color: #fff; /* bg-white */
    color: #1f2937; /* text-blue-800 */
    box-shadow: 0px 2px 4px rgba(15, 22, 35, 0.1), 0px 2px 8px rgba(15, 22, 35, 0.1); /* shadow-xl */
    &:hover {
      background-color: #ffffff; /* bg-white */
    }
  }

  &.collapsed {
    height: 3rem; /* h-12 */
    width: 46px;
    // width: 100%;
    align-items: center;
    .nav-item--label {
      opacity: 0; /* opacity-0 */
      width: 0; /* w-0 */
      overflow: hidden; /* overflow-hidden */
    }
  }

  &:hover {
    background-color: rgba(255, 255, 255, 0.1); /* bg-white/10 */
  }


}

.nav-item--icon {
  width: 1.5rem; /* w-6 */
  height: 1.5rem; /* h-6 */
  opacity: 0.4; /* opacity-40 */
  display: flex;
  flex-shrink: 0; /* flex-shrink-0 */
}

.nav-item--label {
  width: 100%; /* w-full */
  opacity: 1; /* opacity-100 */
  transition: all 0.3s; /* transition-all */
  overflow: hidden; /* overflow-hidden */
}

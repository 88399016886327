.card {
    @apply rounded-md border flex flex-col;
    &--head {
        @apply p-4 pb-0 flex flex-row items-center justify-between;
    }
    &--body {
        @apply p-4;
    }
    &--foot {
        @apply px-4 py-2;
    }
}
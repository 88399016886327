@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  @apply bg-white antialiased;
}

h1 {
  @apply text-2xl font-bold tracking-tight;
}
h2 {
  @apply text-xl font-bold tracking-tight;
}
h3 {
  @apply text-lg font-bold tracking-tight;
}
p {
  @apply text-base;
}

.muted {
  @apply opacity-60;
}

.app-wrapper {
  /* @apply flex flex-row h-screen overflow-y-hidden; */
  min-width: 990px;
  /* .navbar {
  } */
  /* .main {
    @apply flex flex-col flex-1 overflow-hidden relative;
    .top {
      @apply h-16 bg-white border-b flex items-center justify-between px-6 absolute top-0 right-0 left-0;
    }
    .body {
      @apply p-6 mt-16 min-w-full h-full overflow-y-auto;
    }
  } */
}

.bg-live-tracking {
  background: url("../src/assets/images/bg-tracking-card.webp") center center;
  background-size: cover;
  /* .overlay {
    @apply bg-black/0 transition-all;
  } */
  /* &:hover {
    .overlay {
      @apply bg-black/5;
    }
  } */
}

.map-popover {
  padding: 0.5rem; /* p-2 */
  width: 260px; /* w-[260px] */
  color: #1f2937; /* text-slate-900 */
  display: flex;
  flex-direction: column;
  gap: 0.75rem; /* space-y-3 */
}

.map-popover .user {
  display: flex;
  gap: 0.75rem; /* gap-3 */
  align-items: center; /* items-center */
}

.map-popover .user .name {
  font-size: 0.875rem; /* text-sm */
  font-weight: 500; /* font-medium */
}

.map-popover .user .phone {
  font-size: 0.75rem; /* text-xs */
  opacity: 0.7; /* opacity-70 */
}

.map-popover .list-view {
  display: flex;
  justify-content: space-between; /* justify-between */
  font-size: 0.875rem; /* text-sm */
  gap: 1rem; /* gap-4 */
}

.map-popover .list-view .label {
  font-weight: 500; /* font-medium */
}

.map-popover .list-view .data {
  opacity: 0.8; /* opacity-80 */
  flex: 1;
  text-align: right; /* text-right */
}